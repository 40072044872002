import axios from 'axios'
import {ENDPOINTS, getBearer} from '@/config'

export default {
  state: {
    patients: [],
    therapists: []
  },
  mutations: {
    setReportPatientList (state, list) {
      state.patients = list
    },
    setReportTherapistList (state, list) {
      state.therapists = list
    }
  },
  actions: {

    getReportData (context, params) {
      return axios.get(ENDPOINTS.reports, {params, headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

  }
}
