import axios from 'axios'
import {ENDPOINTS, getBearer} from '@/config'

export default {
  state: {
    therapists: [],
    unavailabilityRegisters: [],
    userData: null
  },
  mutations: {
    setTherapistList (state, therapists) {
      state.therapists = therapists
    },
    setUserData (state, image) {
      state.userData = image
    },
    setUnavailabilityRegisters (state, registers) {
      state.unavailabilityRegisters = registers
    }
  },
  actions: {
    async therapistClinicViculates (context, params) {
      return axios.post(ENDPOINTS.therapistVinculate, params, {headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },
    async getDashboardClinicData (context, params) {
      return axios.get(ENDPOINTS.dashboardClinicData, {params, headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },
    async getTherapistClinics ({commit}, params) {
      return axios.get(ENDPOINTS.getTherapistClinics, {params, headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setTherapistList', resp.data)
        }
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    getTherapistConfig () {
      return axios.get(ENDPOINTS.therapistWorkConfig, {headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    createWorkConfig (context, params) {
      return axios.post(ENDPOINTS.therapistWorkConfig, params, {headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    updateWorkConfig (context, params) {
      return axios.put(ENDPOINTS.therapistWorkConfig + params.id, params, {headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    createUnavailabilityRegister (context, params) {
      return axios.post(ENDPOINTS.therapistUnavailabilityRegister, params, {headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    getUnavailabilityRegisters ({commit}) {
      return axios.get(ENDPOINTS.therapistUnavailabilityRegister, {headers: getBearer()}).then(resp => {
        commit('setUnavailabilityRegisters', resp.data)
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    deleteUnavailabilityRegisters (context, id) {
      return axios.delete(ENDPOINTS.therapistUnavailabilityRegister + id, {headers: getBearer()})
    },

    updateTherapist (context, params) {
      return axios.put(`${ENDPOINTS.users}${params.id}`, params, {headers: getBearer()}).then(resp => {
        return resp
      }).catch(error => {
        return error.response
      })
    },

  }
}
