const host = window.location.host
const isProduction = process.env.NODE_ENV === 'production'
let protocol = isProduction ? 'https://' : 'http://'
export const appUrl = protocol + host + '/'
export const endpointUrl = 'https://api.ceu.exocode.digital/'
export const apiUrl = endpointUrl + 'api/'

const fingertechUrl = 'http://localhost:9000/api/public/v1/'

export function getBearer() {
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  if (authUser) {
    return { 'Authorization': 'Bearer ' + authUser.access_token }
  } else {
    getHeaderPublic()
  }
}

export function getFormDataBearer() {
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  if (authUser) {
    return {
      'Authorization': 'Bearer ' + authUser.access_token,
      'Accept': 'application/json, image/*, file/*, video/*',
      'Content-Type': 'multipart/form-data; boundary=something'
    }
  } else {
    getHeaderPublicFormData()
  }
}

export const getHeaderPublic = function() {
  return { 'Accept': 'application/json' }
}

export const getHeaderPublicFormData = function() {
  return { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' }
}
export const _roles = {
  adm_role: 1,
  clinic_role: 2,
  therapist_role: 3,
  patient_role: 4
}

export const _roles_name = [
  '', 'ADM', 'Clínica', 'Terapeuta', 'Paciente'
]

export const _schedule_status = {
  open: {
    label: 'Em Aberto',
    value: null
  },
  patient_present: {
    label: 'Paciente Presente',
    value: '1'
  },
  patient_no_present: {
    label: 'Paciente não Presente',
    value: '2'
  },
  schedule_canceled: {
    label: 'Atendimento Cancelado',
    value: '3'
  },
  reschedule: {
    label: 'Atendimento Reagendado',
    value: '4'
  },
  checkIn: {
    label: 'Check-in',
    value: '5'
  },
  checkOut: {
    label: 'Check-out',
    value: '6'
  }
}

export const _schedule_status_label_map = [
  'Em Aberto',
  'Paciente Presente',
  'Paciente não Presente',
  'Atendimento Cancelado',
  'Atendimento Reagendado'
]

export const ENDPOINTS = {

  oauth: `${apiUrl}login`,
  registerUser: `${apiUrl}register-user`,

  avaliations: `${apiUrl}avaliations/`,
  avaliationsScore: `${apiUrl}avaliations-score/`,
  clinics: `${apiUrl}clinics/`,
  dashboard: `${apiUrl}dashboard/`,
  evolutions: `${apiUrl}evolutions/`,
  notifications: `${apiUrl}notifications/`,
  schedules: `${apiUrl}schedules/`,
  users: `${apiUrl}users/`,

  evolutionMedias: `${apiUrl}evolution-medias`,
  noticeMedias: `${apiUrl}notice-medias`,

  checkRecoveryPassCode: `${apiUrl}check-recovery-password-code`,
  forgotPassword: `${apiUrl}forgot-password`,
  updatePassword: `${apiUrl}update-password/`,
  resetPassword: `${apiUrl}reset-password/`,

  patientEvolutionData: `${apiUrl}patient-evolution-data/`,
  removePatientEvolution: `${apiUrl}remove-patient-evolution-data/`,
  getPatientEvolutionData: `${apiUrl}get-patient-evolution-data/`,
  updatePatientEvolutionData: `${apiUrl}update-patient-evolution-data/`,
  updateFingerprint: `${apiUrl}update-fingerprint/`,

  registerFamilyGroup: `${apiUrl}register-family-group`,
  dashboardClinicData: `${apiUrl}get-dashboard-clinic-data/`,
  getTherapistClinics: `${apiUrl}get-therapists-clinics/`,
  therapistVinculate: `${apiUrl}vinculate-therapist-clinic/`,
  therapistWorkConfig: `${apiUrl}therapist-work-config/`,
  therapistUnavailabilityRegister: `${apiUrl}therapist-unavailability-register/`,
  getTherapistById: `${apiUrl}get-therapist-by-id/`,
  getTherapistSchedules: `${apiUrl}get-therapist-schedules`,
  filter: `${apiUrl}filter/`,

  reports: `${apiUrl}reports/`,
  notices: `${apiUrl}notices/`,

  // fingertech api endpoints
  fingerprintUrl: `${fingertechUrl}captura/Capturar/1`,
  fingerprintCompareUrl: `${fingertechUrl}captura/Comparar`

}
