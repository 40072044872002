import axios from 'axios'
import {ENDPOINTS, getBearer} from '@/config'

export default {
  actions: {

    registerAvaliation (context, params) {
      return axios.post(ENDPOINTS.avaliations, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(resp => {
          return resp.response
        })
    },

    updateAvaliation (context, params) {
      return axios.put(`${ENDPOINTS.avaliations}${params.id}`, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    },

    deleteAvaliation (context, id) {
      return axios.delete(`${ENDPOINTS.avaliations}${id}`, {headers: getBearer()})
    },

    //

    registerAvaliationScore (context, params) {
      return axios.post(ENDPOINTS.avaliationsScore, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(resp => {
          return resp.response
        })
    },

    updateAvaliationScore (context, params) {
      return axios.put(`${ENDPOINTS.avaliationsScore}${params.id}`, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    },

    deleteAvaliationScore (context, id) {
      return axios.delete(`${ENDPOINTS.avaliationsScore}${id}`, {headers: getBearer()})
    },

  }
}
