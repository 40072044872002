import axios from 'axios'
import {ENDPOINTS, getBearer, getFormDataBearer} from '@/config'

export default {
  state: {
    notices: []
  },
  mutations: {
    setNoticeList (state, list) {
      state.notices = list
    }
  },
  actions: {

    registerNotice (context, params) {
      return axios.post(ENDPOINTS.notices, params, {headers: getBearer()}).then(resp => {
        return resp
      }).catch(error => {
        return error
      })
    },

    updateNotice (context, params) {
      return axios.put(`${ENDPOINTS.notices}${params.id}`, params, {headers: getBearer()}).then(resp => {
        return resp
      }).catch(error => {
        return error
      })
    },

    getNotices ({commit}, params) {
      return axios.get(ENDPOINTS.notices, {params, headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setNoticeList', resp.data)
        }
        return resp
      }).catch(error => {
        return error.response
      })
    },

    deleteNotice (context, id) {
      return axios.delete(`${ENDPOINTS.notices}${id}`, {headers: getBearer()})
    },

    uploadNoticeMedias (context, formData) {
      return axios.post(`${ENDPOINTS.noticeMedias}`, formData, {headers: getFormDataBearer()})
        .then(resp => {
          return resp.data
        }).catch(error => {
          return error
        })
    }

  }
}
