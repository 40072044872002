import axios from 'axios'
import { ENDPOINTS, getBearer } from '@/config'

export default {
  state: {
    patients: []
  },
  mutations: {
    setPatientList(state, patients) {
      state.patients = patients
    }
  },
  actions: {

    getAllPatients({ commit }, params) {
      return axios.get(ENDPOINTS.users, { params, headers: getBearer() }).then(resp => {
        if (resp.status === 200) {
          commit('setPatientList', resp.data)
        }
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    registerFamilyGroup(context, params) {
      return axios.post(ENDPOINTS.registerFamilyGroup, params, { headers: getBearer() })
        .then(resp => {
          return resp
        }).catch(resp => {
          return resp.response
        })
    },

    async patientEvolutionData(context, params) {
      return axios.post(ENDPOINTS.patientEvolutionData, params, { headers: getBearer() }).then(resp => {
        return resp.data
      }).catch(err => {
        return err.response
      })
    },
    async GetPatientEvolutionData(context, params) {
      return axios.post(ENDPOINTS.getPatientEvolutionData, params, { headers: getBearer() }).then(resp => {
        return resp.data
      }).catch(err => {
        return err.response
      })
    },
    async updatePatientEvolutionData(context, params) {
      return axios.post(ENDPOINTS.updatePatientEvolutionData, params, { headers: getBearer() }).then(resp => {
        return resp.data
      }).catch(err => {
        return err.response
      })
    },
    async removePatientEvolution(context, params) {
      return axios.post(ENDPOINTS.removePatientEvolution, params, { headers: getBearer() }).then(resp => {
        return resp
      }).catch(err => {
        return err.response
      })
    },

    getPatient(context, params) {
      return axios.get(ENDPOINTS.users + params.id, { params, headers: getBearer() }).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    updatePatient(context, params) {
      return axios.put(`${ENDPOINTS.users}${params.id}`, params, { headers: getBearer() }).then(resp => {
        return resp
      }).catch(error => {
        return error.response
      })
    },

  }
}
