import axios from 'axios'
import {ENDPOINTS, getBearer} from '@/config'

export default {
  state: {
    schedules: []
  },
  mutations: {
    setScheduleList (state, schedules) {
      state.schedules = schedules
    }
  },
  actions: {

    setScheduleList ({commit}, schedules) {
      commit('setScheduleList', schedules)
    },

    getAllSchedules (context, params) {
      return axios.get(ENDPOINTS.schedules, {params, headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    getSchedule (context, params) {
      return axios.get(ENDPOINTS.schedules + params.id, {headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    updateSchedule (context, params) {
      return axios.put(ENDPOINTS.schedules + params.id, params, {headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    createSchedule (context, params) {
      return axios.post(ENDPOINTS.schedules, params, {headers: getBearer()}).then(resp => {
        return resp
      }).catch(resp => {
        return resp
      })
    }

  }
}
