import Vue from 'vue'
import './assets/font-awesome-4.7.0/css/font-awesome.min.css'
import App from './App.vue'
import router from './router'
import store from './store'
import {MediaQueries, CommonBands} from 'vue-media-queries'
import '@/plugin/filters'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'

const mediaQueries = new MediaQueries({
  bands: CommonBands.Bulma
})
Vue.use(mediaQueries)

import VueResource from 'vue-resource'
import './registerServiceWorker'

Vue.use(VueResource)

Vue.config.productionTip = false

let path = '/'

router.beforeEach((to, from, next) => {
  store.dispatch('hideAlert')
  if (to.meta.requires_auth) {
    let authUser = JSON.parse(window.localStorage.getItem('authUser'))
    if (!authUser || (authUser && !authUser.user)) {
      window.localStorage.clear()
      next('/login')
      return false
    } else if (to.path === '/') {
      next('/dashboard')
      return false
    }
  }
  path = to.path
  next()
})

axios.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    }
  },
  error => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          if (error.response.data.error === 'invalid_token' && path !== 'login') {
            window.localStorage.clear()
            window.location = '/'
            break
          }
      }
      return Promise.reject(error.response);
    } else {
      let authUser = JSON.parse(window.localStorage.getItem('authUser'))
      if (error.code === "ERR_NETWORK") {
        if (authUser) {
          window.localStorage.clear()
          window.location = '/'
        } else {
          alert('Sistema temporariamente indisponível.')
          return false
        }
      }
    }
  }
);

new Vue({
  router,
  store,
  mediaQueries: mediaQueries,
  render: h => h(App)
}).$mount('#app')
