import axios from 'axios'
import {ENDPOINTS, getBearer, getFormDataBearer} from '@/config'

export default {
  actions: {

    registerEvolution (context, params) {
      return axios.post(ENDPOINTS.evolutions, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(resp => {
          return resp.response
        })
    },

    updateEvolution (context, params) {
      return axios.put(`${ENDPOINTS.evolutions}${params.id}`, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    },

    deleteEvolution (context, id) {
      return axios.delete(`${ENDPOINTS.evolutions}${id}`, {headers: getBearer()})
    },

    deleteMediaFile (context, id) {
      return axios.delete(`${ENDPOINTS.evolutionMedias}/${id}`, {headers: getBearer()})
    },

    uploadMedias (context, formData) {
      return axios.post(`${ENDPOINTS.evolutionMedias}`, formData, {headers: getFormDataBearer()})
        .then(resp => {
          return resp.data
        }).catch(error => {
          return error
        })
    }

  }
}
