import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import axios from 'axios'
import {apiUrl} from '@/config'

import alertStore from '@/store/alertStore'
import avalationStore from '@/store/avaliationStore'
import clinicStore from '@/store/clinicStore'
import evolutionStore from '@/store/evolutionStore'
import noticeBoardStore from '@/store/noticeBoardStore'
import notificationStore from '@/store/notificationStore'
import patientStore from '@/store/patientStore'
import reportStore from '@/store/reportStore'
import scheduleStore from '@/store/scheduleStore'
import userStore from '@/store/userStore'
import therapistStore from './therapistStore'

axios.defaults.baseURL = apiUrl

export default new Vuex.Store({
  modules: {
    alertStore,
    avalationStore,
    evolutionStore,
    clinicStore,
    noticeBoardStore,
    notificationStore,
    patientStore,
    reportStore,
    scheduleStore,
    userStore,
    therapistStore
  }
})
