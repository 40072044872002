import axios from 'axios'
import { ENDPOINTS, getBearer } from '@/config'

export default {
  state: {
    clinics: []
  },
  mutations: {
    setClinicList(state, clinics) {
      state.clinics = clinics
    }
  },
  actions: {

    getAllClinics({ commit }) {
      return axios.get(ENDPOINTS.clinics, { headers: getBearer() }).then(resp => {
        commit('setClinicList', resp.data)
      }).catch(error => {
        return error.response
      })
    },

    getClinic(context, id) {
      return axios.get(ENDPOINTS.clinics + id, { headers: getBearer() }).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    async getTherapistById(context, params) {
      return axios.post(ENDPOINTS.getTherapistById, params, { headers: getBearer() }).then(resp => {
        return resp
      }).catch(error => {
        return error
      })
    },

    async filter(context, params) {
      return axios.post(ENDPOINTS.filter, params, { headers: getBearer() }).then(resp => {
        return resp
      }).catch(error => {
        return error
      })
    },

    async getTherapistSchedules(context, params) {
      return axios.post(ENDPOINTS.getTherapistSchedules, params, { headers: getBearer() }).then(resp => {
        return resp
      }).catch(error => {
        return error
      })
    },

    createClinic(context, params) {
      return axios.post(ENDPOINTS.clinics, params, { headers: getBearer() }).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    updateClinic(context, params) {
      return axios.put(ENDPOINTS.clinics + params.id, params, { headers: getBearer() }).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    deleteClinic(context, id) {
      return axios.delete(`${ENDPOINTS.clinics}${id}`, { headers: getBearer() })
    }

  }
}
