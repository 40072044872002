import axios from 'axios'
import {ENDPOINTS, getBearer} from '@/config'

function authUser () {
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  return authUser ? authUser : {user: null}
}

export default {
  state: {
    userData: null
  }, mutations: {
    setUserData (state, image) {
      state.userData = image
    }
  }, actions: {

    setUserData ({commit}, data) {
      commit('setUserData', data)
    },

    login ({commit}, params) {
      return axios.post(ENDPOINTS.oauth, params, {}).then(resp => {
        commit('setUserData', resp.data.user)
        window.localStorage.setItem('authUser', JSON.stringify(resp.data))
        return resp
      }).catch(error => {
        return error.response
      })
    },

    registerUser (context, params) {
      return axios.post(ENDPOINTS.registerUser, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(resp => {
          return resp.response
        })
    },

    forgotPassword (context, params) {
      return axios.post(ENDPOINTS.forgotPassword, params, {}).then(resp => {
        return resp
      }).catch(error => {
        return error.response
      })
    },

    checkRecoveryPasswordCode (context, params) {
      return axios.post(ENDPOINTS.checkRecoveryPassCode, params, {}).then(resp => {
        return resp
      }).catch(error => {
        return error.response
      })
    },

    updatePassword (context, params) {
      return axios.put(`${ENDPOINTS.updatePassword}${params.user_id}`, params, {}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    getUser ({commit}, email) {
      return axios.get(`${ENDPOINTS.users}${email}`).then(resp => {
        let auth = authUser()
        auth.user = resp.data
        window.localStorage.setItem('authUser', JSON.stringify(auth))
        commit('setUserImage', auth.user.image)
        return resp.data
      })
    },

    getAllUsers (context, params) {
      return axios.get(ENDPOINTS.users, {params, headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    updateUser ({commit}, params) {
      return axios.put(`${ENDPOINTS.users}${params.id}`, params, {headers: getBearer()}).then(resp => {
        commit('setUserData', resp.data)
        let authUser = JSON.parse(window.localStorage.getItem('authUser'))
        authUser.user = resp.data
        window.localStorage.setItem('authUser', JSON.stringify(authUser))
      }).catch(error => {
        return error.response
      })
    },

    deleteUser (context, id) {
      return axios.delete(`${ENDPOINTS.users}${id}`, {headers: getBearer()})
    },

    getDashboarData () {
      return axios.get(ENDPOINTS.dashboard, {headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error.response
      })
    },

    resetPassword (context, id) {
      return axios.post(ENDPOINTS.resetPassword, {id: id}, {headers: getBearer()}).then(resp => {
        return resp
      }).catch(error => {
        return error.response
      })
    },

    captureFingerprint (context, user) {
      return axios.get(ENDPOINTS.fingerprintUrl).then(resp => {
        if (resp.status === 200) {
          let respData = resp.data
          if (resp.data !== null && respData !== '') {
            user.fingerprint = respData
            return axios.put(`${ENDPOINTS.updateFingerprint}${user.id}`, user, {headers: getBearer()})
              .then(resp => {
                return resp
              }).catch(error => {
                return error.response
              })
          } else {
            return {status: 500, message: respData}
          }
        } else {
          return {status: 500, message: resp}
        }
      })
    },

    compareFingerprint (context, fingerprint) {
      return axios.get(`${ENDPOINTS.fingerprintCompareUrl}?Digital=${fingerprint}`).then(resp => {
        return {status: resp.data !== '' ? 200 : 401}
      })
    }

  }
}
