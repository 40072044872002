import Vue from 'vue'
import moment from 'moment'
import Inputmask from 'inputmask'

Vue.filter('formatDate', function (date) {
  return date ? moment(date).format('DD/MM/YYYY') : ''
})

Vue.filter('formatHour', function (hour) {
  return hour ? moment(hour).format('HH:mm:ss') : ''
})

Vue.filter('formatDatetime', function (date) {
  return date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : ''
})

Vue.filter('formatDatetimeWithoutSeconds', function (date) {
  return date ? moment(date).format('DD/MM/YYYY HH:mm') : ''
})

Vue.filter('shortName', function (name) {
  return name ? name.split(' ')[0] : ''
})

Vue.filter('currence', function (value) {
  if (value == null) {
    return value
  } else {
    if (typeof value === 'number') {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
    } else {
      return parseFloat(value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
    }
  }
})

Vue.filter('truncateStr', function (string) {
  if (string.length >= 20) {
    return string.substring(0, 20) + '...'
  } else {
    return string
  }
})

Vue.filter('formatCpfCnpj', function (str) {
  if (str) {
    str = str.replace(/\D+/g, '')
    str = str.trim()
    if (str.length === 11) {
      return (str.substr(0, 3) + '.' + str.substr(3, 3) + '.' + str.substr(6, 3) + '-' + str.substr(9, 2))
    } else if (str.length === 14) {
      return (str.substr(0, 2) + '.' + str.substr(2, 3) + '.' + str.substr(5, 3) + '/' + str.substr(8, 4) + '-' + str.substr(12, 2))
    } else if (str.length === 8) {
      return (str.substr(0, 5) + '-' + str.substr(5, 8))
    }
  } else {
    return ''
  }
})

Vue.filter('formatPhone', function (value) {
  if (value) {
    value = value.replace('.', '').replace('-', '').replace('.', '').replace('(', '').replace(')', '').replace(' ', '')
    if (value.length === 11) {
      value = '(' + value.substr(0, 2) + ') ' + ' ' + value.substr(2, 1) + ' ' + value.substr(3, 4) + '-' + value.substr(7, 6)
      return value
    } else if (value.length === 10) {
      value = '(' + value.substr(0, 2) + ') ' + value.substr(2, 4) + '-' + value.substr(6, 6)
      return value
    } else {
      return value
    }
  }
})
Vue.directive('mask', (el, binding) => {
  const {value} = binding
  return Inputmask({mask: value, keepStatic: true}).mask(el)
})
